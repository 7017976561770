<template>
  <div class="err">
    <h4>404</h4>
    <p>出错啦，找不到指定页面~~</p>
    <router-link class="btn" to="/">返回首页</router-link>
  </div>
</template>

<script>
  export default {
    data(){
    }
  }
</script>

<style scoped>
  .err{
    position: relative;
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .err h4{ font-size: 30px; color: gray; margin: 0; }
  .err p{ font-size: 14px; color: gray; margin: 5px 0 100px; }
  .btn{ color: #1f25ae; border:solid 1px #1f25ae; width: 200px; height: 40px; line-height: 40px; text-align: center; border-radius: 4px; }
</style>
